import { pluralize } from "../../utils/pluralize"

export function mapToPost(edge) {

  const { frontmatter: { cover, lead, title, excerpt, path }, timeToRead, html } = edge.node
  const imageSm = cover && cover.childImageSharp.sm
  const imageMob = cover && cover.childImageSharp.mob
  const imageLead = lead && lead.childImageSharp.fixed

  return {
    title,
    imageSm,
    imageMob,
    imageLead,
    html,
    timeToRead,
    excerpt,
    path: `/blog${path}`,
  }
}


export function formatTimeToRead(timeToRead) {
  return `${timeToRead} ${pluralize(timeToRead, "минута", "минуты", "минут")} для чтения`
}
