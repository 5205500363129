import React from "react"
import styles from "./hero-blog-page.module.scss"

const HeroBlogPage = () => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <div className={styles.heading}>Блог Параплан</div>
      <p className={styles.subheading}>Найдите то, что будет интересно именно Вам.</p>
    </div>
  </div>
)

export default HeroBlogPage
