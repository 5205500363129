import React from "react"
import styles from "./blog-post-card.module.scss"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import timeToRead from "../../images/blog/time-to-read.svg"
import { formatTimeToRead } from "./blog-utils"

const BlogPostCard = ({ post, lead, recommend }) => {
  return <div className={`${styles.card} ${lead ? styles.lead : ""} ${recommend ? styles.recommend : ""}`}>
    <div className={styles.imageWrapper}>
      <Link to={post.path}>
        <div className={styles.image}>
          {post.imageMob && <Img fluid={post.imageMob}
                                 objectFit="cover"
                                 objectPosition="50% 50%"
                                 className={styles.mobile}
                                 alt={""}/>}
          {!lead && !recommend && post.imageSm && <Img fluid={post.imageSm}
                                         className={styles.desktop}
                                         objectFit="cover"
                                         alt={""}/>}
          {!lead && recommend && post.imageSm && <Img fluid={post.imageSm}
                                         className={styles.desktop}
                                         objectFit="contain"
                                         style={{"height" : "80px"}}
                                         alt={""}/>}
          {lead && post.imageLead && <Img fixed={post.imageLead}
                                          className={styles.desktop}
                                          alt={""}/>}
        </div>
      </Link>
    </div>
    <div className={styles.page}>
      <div className={styles.timeToRead}>
        <img src={timeToRead}
             alt={""}/> {formatTimeToRead(post.timeToRead)}
      </div>
      <div className={styles.title}>
        <Link to={post.path}>{post.title}</Link>
      </div>
      <div className={styles.excerpt}>
        {post.excerpt}
      </div>
      <div className={styles.readMore}>
        <Link to={post.path}>Подробнее &raquo;</Link>
      </div>
    </div>
  </div>
}

export default BlogPostCard