import React from "react"
import Layout from "../../components/layout"
import HeroBlogPage from "../../components/pages/shared/hero/blog-page"
import styles from "./blog-post.module.scss"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import { openOverlay } from "../../utils/overlay"
import timeToRead from "../../images/blog/time-to-read.svg"
import { formatTimeToRead } from "./blog-utils"
import SEO from "../../components/seo"
import Recomedations from "./blog-recomendations"
import Breadcrumb from '../../components/controls/breadcrumbs';

const BlogPost = (props) => {
  const title = props.data.markdownRemark.frontmatter.title
  const description = props.data.markdownRemark.frontmatter.description
  const greyBlock = props.data.markdownRemark.frontmatter.greyBlock
  const html = props.data.markdownRemark.html
  const image = props.data.markdownRemark.frontmatter.cover
    && props.data.markdownRemark.frontmatter.cover.childImageSharp.fluid

  const pageSlug = `post-${props.data.markdownRemark.frontmatter.path.substr(1)}`

  const timeToReadMinutes = props.data.markdownRemark.timeToRead
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Блог Параплан', url: '/blog'},
    { name: 'Статья', url: ''},
  ];

  return (
    <Layout headerColor={"#03C4A4"}
      page={pageSlug}>
      <SEO title={title}
        description={description}
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroBlogPage />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <div className={`container ${styles.blogPost}`}>
        <div className={styles.timeToRead}>
          <img src={timeToRead}
            alt={""} /> {formatTimeToRead(timeToReadMinutes)}
        </div>
        <h1 className={styles.title}>{title}</h1>
        {image && <Img fluid={image} className={styles.image} />}
        <div className={styles.body}
          dangerouslySetInnerHTML={{ __html: html }} />
        {greyBlock ?
          <div className={styles.greyBlock}>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: greyBlock}}></div>
            <div className={styles.cta}>
              <button type={"button"}
                className={styles.ctaButton}
                onClick={() => openOverlay(pageSlug, "article", "bottom")}>Попробовать бесплатно
              </button>
            </div>
          </div>
          : <div className={styles.cta}>
            <button type={"button"}
              className={styles.ctaButton}
              onClick={() => openOverlay(pageSlug, "article", "bottom")}>Попробовать бесплатно
            </button>
          </div>
        }
        <Recomedations />
      </div>
      <ContactFormComponent />
    </Layout>
  )
}

export const query = graphql`
query($pathSlug: String!) {
  markdownRemark(frontmatter: {path: { eq: $pathSlug }}) {
    html
    timeToRead
    frontmatter {
      title
      path
      greyBlock
      description
      cover {
        childImageSharp {
          fluid(maxWidth: 835) {
            ...GatsbyImageSharpFluid_noBase64
          }
        } 
      }
    }
  }
}
`

export default BlogPost
